import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import { Blog } from '@carousel/nexus';

const baseUrl = import.meta.env.VITE_BFF_BASE_URL || 'https://bff.carousel.gg';

const createBlog = createAsyncThunk(
  'adminNews/createBlog',
  async ({
    id,
    title,
    content,
    imageUrl,
    alt,
    author,
    organizationId,
    divisionId,
    subDivisionId,
  }: Partial<Blog>): Promise<Blog> => {
    const url = `${baseUrl}/news/blog/`;

    const body: Partial<Blog> = {
      id,
      title,
      alt,
      content,
      imageUrl,
      author,
      ...(organizationId ? { organizationId } : {}),
      ...(organizationId && divisionId ? { divisionId } : {}),
      ...(divisionId && subDivisionId ? { subDivisionId } : {}),
    };

    const response = await axios.post(url, body);

    if (response.status !== 201) {
      throw new Error('Error updating blog');
    }
    return response.data as Blog;
  },
);

const deleteBlog = createAsyncThunk(
  'adminNews/deleteBlog',
  async ({ blogId }: { blogId?: string }): Promise<void> => {
    let url = `${baseUrl}/news/blog/${blogId}/`;

    const response = await axios.delete(url);

    if (response.status !== 200) {
      throw new Error('Error deleting blog');
    }
    return;
  },
);

const fetchBlog = createAsyncThunk(
  'adminNews/fetchBlog',
  async ({ blogId }: { blogId?: string | null }): Promise<Blog> => {
    let url = `${baseUrl}/news/blog/${blogId}/`;

    const response = await axios.get(url);

    if (response.status !== 200) {
      throw new Error('Error fetching blog');
    }
    return response.data.blog as Blog;
  },
);

const fetchBlogs = createAsyncThunk(
  'adminNews/fetchBlogs',
  async ({
    organizationId,
    divisionId,
    subDivisionId,
  }: {
    organizationId?: string | null;
    divisionId?: string | null;
    subDivisionId?: string | null;
  }): Promise<Blog[]> => {
    let url = `${baseUrl}/news/blogs/${organizationId}/`;

    if (divisionId) {
      url += `${divisionId}/`;
      if (subDivisionId) {
        url += `${subDivisionId}/`;
      }
    }

    const response = await axios.get(url);

    if (response.status !== 200) {
      throw new Error('Error fetching blogs');
    }
    return response.data.blogs as Blog[];
  },
);

const updateBlog = createAsyncThunk(
  'adminNews/updateBlog',
  async ({
    id,
    title,
    content,
    imageUrl,
    alt,
    author,
    organizationId,
    divisionId,
    subDivisionId,
  }: Partial<Blog>): Promise<Blog> => {
    let url = `${baseUrl}/news/blog/${id}/`;

    const body: Partial<Blog> = {
      id,
      title,
      alt,
      content,
      imageUrl,
      author,
      ...(organizationId ? { organizationId } : {}),
      ...(organizationId && divisionId ? { divisionId } : {}),
      ...(organizationId && divisionId && subDivisionId
        ? { subDivisionId }
        : {}),
    };

    const response = await axios.put(url, body);

    if (response.status !== 200) {
      throw new Error('Error updating blog');
    }
    return response.data.article as Blog;
  },
);

export { createBlog, deleteBlog, fetchBlog, fetchBlogs, updateBlog };
