import { createSlice } from '@reduxjs/toolkit';
import { Blog } from '@carousel/nexus';

import { initialNewsState } from '../state/news.state';
import { errorHandler } from '../../utils/error-handler.util';
import { fetchBlog, fetchNewsFeed } from '../../services/news/news.service';

const newsSlice = createSlice({
  name: 'news',
  initialState: initialNewsState,
  reducers: {},
  extraReducers: (builder) => {
    /* Fetch Blog */
    builder.addCase(fetchBlog.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchBlog.fulfilled, (state, action) => {
      state.loading = false;
      state.selected = action.payload as Blog;
      state.error = null;
    });
    builder.addCase(fetchBlog.rejected, (state, action) => {
      state.loading = false;
      state.items = [];
      state.error = errorHandler(action.error);
    });

    /* Fetch News */
    builder.addCase(fetchNewsFeed.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchNewsFeed.fulfilled, (state, action) => {
      state.loading = false;
      state.items = action.payload as Blog[] | [];
      state.error = null;
    });
    builder.addCase(fetchNewsFeed.rejected, (state, action) => {
      state.loading = false;
      state.items = [];
      state.error = errorHandler(action.error);
    });
  },
});

const { actions, reducer } = newsSlice;

const {} = actions;

export {};
export { reducer as newsReducer };
