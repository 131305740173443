import { Rules } from '@carousel/nexus';
import { CarouselError } from '../../types/errors/carousel-error.type';

type RulesState = {
  loading: boolean;
  selected: Rules | null;
  error: CarouselError | null;
};

const initialRulesState: RulesState = {
  loading: false,
  selected: null,
  error: null,
};

export { initialRulesState };
export type { RulesState };
