enum IconName {
  BAR_CHART_2,
  BOOK,
  BUILDING_2,
  CHART,
  CIRCLE_GUAGE,
  GAVEL,
  HELP,
  HELP_CIRCLE,
  HOME,
  GAMEPAD,
  GAMEPAD_2,
  NEWSPAPER,
  NOTEPAD,
  NOTEPAD_TEXT,
  SETTINGS,
  SQUARE_SPLIT_HORIZONTAL,
}

export { IconName };
