import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import { BannerItem } from '@carousel/nexus';

const baseUrl = import.meta.env.VITE_BFF_BASE_URL || 'https://bff.carousel.gg';

type CreateImageProps = {
  organizationId?: string;
  divisionId?: string;
  subDivisionId?: string;
  title: string;
  description: string;
  fileContent: string;
  alt?: string;
};

type FetchBannersProps = {
  organizationId?: string;
  divisionId?: string;
  subDivisionId?: string;
};

type UpdateImageProps = {
  title: string;
  description: string;
  fileContent: string;
  alt?: string;
};

const createImage = createAsyncThunk(
  'adminBanners/createImage',
  async (props: CreateImageProps): Promise<BannerItem> => {
    let url = `${baseUrl}/news/banners`;
    if (props.organizationId) {
      url += `/${props.organizationId}`;
      if (props.divisionId) {
        url += `/${props.divisionId}`;
        if (props.subDivisionId) {
          url += `/${props.subDivisionId}`;
        }
      }
    }

    const body = {
      title: props.title,
      description: props.description,
      fileContent: props.fileContent,
      alt: props.alt,
    };

    const response = await axios.post(url, body);

    if (response.status !== 201) {
      throw new Error('Error creating banner image');
    }
    return response.data as BannerItem;
  },
);

const deleteImage = createAsyncThunk(
  'adminBanners/deleteImage',
  async ({ bannerId }: { bannerId: string }): Promise<BannerItem> => {
    const url = `${baseUrl}/news/banners/${bannerId}`;

    const response = await axios.delete(url);

    if (response.status !== 200) {
      throw new Error('Error updating banner image');
    }
    return response.data as BannerItem;
  },
);

const fetchImage = createAsyncThunk(
  'adminBanners/fetchImage',
  async ({ bannerId }: { bannerId: string }): Promise<BannerItem> => {
    let url = `${baseUrl}/news/banner/${bannerId}`;

    const response = await axios.get(url);

    if (response.status !== 200) {
      throw new Error('Error fetching image');
    }
    return response.data.banners as BannerItem;
  },
);

const fetchImages = createAsyncThunk(
  'adminBanners/fetchImages',
  async (props: FetchBannersProps): Promise<BannerItem[]> => {
    let url = `${baseUrl}/news/banners`;
    if (props.organizationId) {
      url += `/${props.organizationId}`;
      if (props.divisionId) {
        url += `/${props.divisionId}`;
        if (props.subDivisionId) {
          url += `/${props.subDivisionId}`;
        }
      }
    }

    const response = await axios.get(url);

    if (response.status !== 200) {
      throw new Error('Error fetching images');
    }
    return response.data.banners as BannerItem[];
  },
);

const updateImage = createAsyncThunk(
  'adminBanners/updateImage',
  async ({
    bannerId,
    props,
  }: {
    bannerId: string;
    props: UpdateImageProps;
  }): Promise<BannerItem> => {
    const url = `${baseUrl}/news/banners/${bannerId}`;

    const body = {
      title: props.title,
      description: props.description,
      fileContent: props.fileContent,
      alt: props.alt,
    };

    const response = await axios.put(url, body);

    if (response.status !== 200) {
      throw new Error('Error updating banner image');
    }
    return response.data as BannerItem;
  },
);

export { createImage, deleteImage, fetchImage, fetchImages, updateImage };
