/**
 * Checks if a navigation item should be considered active based on the current route
 * This improved version handles deeply nested routes with more precise matching
 *
 * @param currentPath - The current route path from location.pathname
 * @param navItemPath - The path of the navigation item being checked
 * @returns boolean indicating if the navigation item should be considered active
 */
export const isActive = (currentPath: string, navItemPath: string): boolean => {
  // If the paths are exactly the same, it's definitely active
  if (currentPath === navItemPath) {
    return true;
  }

  // Skip the check if either path is empty
  if (!currentPath || !navItemPath) {
    return false;
  }

  // Special case for root path - only exact match works
  if (navItemPath === '/') {
    return currentPath === '/';
  }

  // Standardize paths by ensuring they start with / and removing trailing /
  const standardizedCurrentPath = currentPath.startsWith('/')
    ? currentPath.endsWith('/') && currentPath.length > 1
      ? currentPath.slice(0, -1)
      : currentPath
    : `/${currentPath}`;

  const standardizedNavPath = navItemPath.startsWith('/')
    ? navItemPath.endsWith('/') && navItemPath.length > 1
      ? navItemPath.slice(0, -1)
      : navItemPath
    : `/${navItemPath}`;

  // Split both paths into segments for comparison
  const currentSegments = standardizedCurrentPath.split('/').filter(Boolean);
  const navSegments = standardizedNavPath.split('/').filter(Boolean);

  // Handle organization routes - both /org/ and /manage/org/ patterns
  if (
    (currentSegments.length >= 3 && currentSegments[0] === 'org') ||
    (currentSegments.length >= 3 &&
      currentSegments[0] === 'manage' &&
      currentSegments[1] === 'org')
  ) {
    // For specific routes like manage/org/:orgName/content/news
    // We only want to match parent routes exactly up to their defined path
    // Prevent false activation when navItemPath doesn't contain the specific route

    // First, check if nav path is for a specific section (content, divisions, etc.)
    if (
      navSegments.length >= 4 &&
      navSegments[0] === 'manage' &&
      navSegments[1] === 'org'
    ) {
      // Compare the specific section (e.g., content, divisions)
      // Nav path should match current path up to its length AND
      // the section identifier should match
      const navSection = navSegments[3]; // e.g., "content", "divisions"
      const currentSection = currentSegments[3]; // corresponding section in current path

      // Match only if the section matches and all segments match up to nav path length
      let sectionsMatch = navSection === currentSection;

      // If sections match, ensure all segments up to nav length match
      if (sectionsMatch) {
        for (let i = 0; i < navSegments.length; i++) {
          // Skip organization name comparison (using placeholder)
          if (i === 2) continue;

          if (
            i >= currentSegments.length ||
            navSegments[i] !== currentSegments[i]
          ) {
            sectionsMatch = false;
            break;
          }
        }
      }

      return sectionsMatch;
    }

    // For the general organization routes (org/:orgName or manage/org/:orgName),
    // handle separately based on route type

    // Regular org route (non-management)
    if (navSegments.length === 2 && navSegments[0] === 'org') {
      // For org/:orgName routes, check for exact match or child paths
      if (currentSegments[0] === 'org') {
        // Either exact match or correct child path
        return (
          currentSegments.length >= 2 &&
          (standardizedCurrentPath === standardizedNavPath ||
            (standardizedCurrentPath.startsWith(standardizedNavPath) &&
              standardizedCurrentPath.charAt(standardizedNavPath.length) ===
                '/'))
        );
      }
      return false;
    }

    // Management org route
    if (
      navSegments.length === 3 &&
      navSegments[0] === 'manage' &&
      navSegments[1] === 'org'
    ) {
      // Match only the exact path or the details tab
      return (
        standardizedCurrentPath === standardizedNavPath ||
        (currentSegments.length === 4 && currentSegments[3] === 'details')
      );
    }
  }

  // General case for other routes - ensure parent/child relationship
  // First check if current path starts with nav path to establish parent/child
  if (!standardizedCurrentPath.startsWith(standardizedNavPath)) {
    return false;
  }

  // Then check if it's a true parent/child by ensuring there's a path separator
  // This prevents /content matching /content-something
  if (
    standardizedCurrentPath.length > standardizedNavPath.length &&
    standardizedCurrentPath.charAt(standardizedNavPath.length) !== '/'
  ) {
    return false;
  }

  // At this point we know current path starts with nav path and is followed by /
  // so it's a true parent/child relationship
  return true;
};
