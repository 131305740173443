import React, { useState, useEffect } from 'react';
import {
  Shield,
  AlertCircle,
  Eye,
  Key,
  Globe,
  BookOpen,
  Mail,
  ArrowUp,
} from 'lucide-react';
import { useOutletContext } from 'react-router-dom';
import { ColorScheme } from '@carousel/nexus';

type OutletContext = {
  colors: ColorScheme;
};

const PrivacyPolicy = () => {
  // Get colors from outlet context similar to other components
  const { colors } = useOutletContext<OutletContext>();

  // State for tracking which section is active
  const [activeSection, setActiveSection] = useState('introduction');

  // State for back to top button visibility
  const [showBackToTop, setShowBackToTop] = useState(false);

  // Handle scroll events to show/hide back to top button
  useEffect(() => {
    const handleScroll = () => {
      setShowBackToTop(window.scrollY > 400);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Scroll to top function
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  // Sections of the privacy policy
  const sections = [
    {
      id: 'introduction',
      title: 'Introduction',
      icon: <BookOpen className={`${colors.text.accent3} w-6 h-6`} />,
      content: `
        <p>This Privacy Policy describes how Carousel ("we", "our", or "us") collects, uses, and shares your personal information when you use our platform.</p>
        <p class="mt-4">We value your privacy and are committed to protecting your personal data. This policy will inform you about how we look after your personal information and tell you about your privacy rights.</p>
        <p class="mt-4">Carousel's services are available to users aged 13 and older. We take additional measures to protect the privacy of users between 13-18 years of age.</p>
      `,
    },
    {
      id: 'information-we-collect',
      title: 'Information We Collect',
      icon: <Eye className={`${colors.text.accent3} w-6 h-6`} />,
      content: `
        <p>We collect minimal personal information to verify your identity and prevent cheating ("smurfing"). This includes:</p>
        <ul class="list-disc pl-8 my-4 space-y-2">
          <li>Full name</li>
          <li>Email address</li>
          <li>Phone number</li>
          <li>Birth date (to verify age eligibility)</li>
        </ul>
        <p>This information is collected solely for identity verification purposes, age verification, and to maintain the integrity of our competitive gaming environment.</p>
      `,
    },
    {
      id: 'third-party-services',
      title: 'Third-Party Services',
      icon: <Globe className={`${colors.text.accent3} w-6 h-6`} />,
      content: `
        <p>We use Okta, a third-party identity management service provider, to handle user authentication and identity verification. Please review <a href="https://www.okta.com/privacy-policy/" target="_blank" rel="noopener noreferrer" class="underline hover:opacity-80 text-blue-400">Okta's privacy policy</a> for information on how they process your data.</p>
        <p class="mt-4">Carousel itself does not directly store your personal information on our servers.</p>
      `,
    },
    {
      id: 'cookies',
      title: 'Cookies and Tracking',
      icon: <Key className={`${colors.text.accent3} w-6 h-6`} />,
      content: `
        <p>Carousel does not directly use cookies or tracking technologies for our own purposes. However, our identity provider (Okta) may use cookies or similar technologies to maintain your session and authentication status.</p>
        <p class="mt-4">Please refer to Okta's cookie policy for more information about their use of cookies and how you can manage them.</p>
      `,
    },
    {
      id: 'data-sharing',
      title: 'Data Sharing',
      icon: <Shield className={`${colors.text.accent3} w-6 h-6`} />,
      content: `
        <p>We do not sell, rent, or trade your personal information with any third parties.</p>
        <p class="mt-4">Your information may be shared with:</p>
        <ul class="list-disc pl-8 my-4 space-y-2">
          <li>Okta (our identity provider) to authenticate your account</li>
          <li>Service providers who help us operate our platform</li>
          <li>Law enforcement agencies, courts, or other government authorities when required by applicable laws</li>
        </ul>
      `,
    },
    {
      id: 'children-privacy',
      title: "Children's Privacy",
      icon: <Shield className={`${colors.text.accent3} w-6 h-6`} />,
      content: `
        <p>Carousel's services are available to users 13 years of age and older. We collect birth date information to verify age eligibility and do not knowingly collect personal information from children under 13. If you are a parent or guardian and believe we have collected information from a child under 13, please contact us immediately at <a href="mailto:support@carousel.gg" class="underline hover:opacity-80 text-blue-400">support@carousel.gg</a>.</p>
        <p class="mt-4">For users between 13 and 18 years of age:</p>
        <ul class="list-disc pl-8 my-4 space-y-2">
          <li>We collect only the minimum information necessary for identity verification and age confirmation</li>
          <li>Parents or legal guardians may request access to review their child's information</li>
          <li>Parents or legal guardians may request deletion of their child's information</li>
          <li>We do not use personal information of users under 18 for marketing purposes</li>
        </ul>
        <p>If you're a parent or guardian of a user between 13-18 years old and have questions about their account, please contact us at <a href="mailto:support@carousel.gg" class="underline hover:opacity-80 text-blue-400">support@carousel.gg</a>.</p>
      `,
    },
    {
      id: 'your-rights',
      title: 'Your Privacy Rights',
      icon: <AlertCircle className={`${colors.text.accent3} w-6 h-6`} />,
      content: `
        <p>Depending on your location, you may have certain rights regarding your personal information:</p>
        <ul class="list-disc pl-8 my-4 space-y-2">
          <li>Right to access your personal information</li>
          <li>Right to correct inaccurate information</li>
          <li>Right to request deletion of your information</li>
          <li>Right to object to certain processing activities</li>
        </ul>
        <p>To exercise any of these rights, including CCPA requests, please email <a href="mailto:support@carousel.gg" class="underline hover:opacity-80 text-blue-400">support@carousel.gg</a> with your request.</p>
      `,
    },
    {
      id: 'policy-updates',
      title: 'Policy Updates',
      icon: <BookOpen className={`${colors.text.accent3} w-6 h-6`} />,
      content: `
        <p>We may update this Privacy Policy from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons.</p>
        <p class="mt-4">We will notify you of any material changes by posting the new Privacy Policy on this page and updating the "Last Updated" date.</p>
      `,
    },
    {
      id: 'contact',
      title: 'Contact Us',
      icon: <Mail className={`${colors.text.accent3} w-6 h-6`} />,
      content: `
        <p>If you have any questions about this Privacy Policy or our privacy practices, please contact us at:</p>
        <p class="mt-3 flex items-center">
          <span class="font-medium mr-2">Email:</span> 
          <a href="mailto:support@carousel.gg" class="underline hover:opacity-80 text-blue-400">support@carousel.gg</a>
        </p>
        <p class="mt-4">We are committed to working with you to obtain a fair resolution of any complaint or concern about privacy.</p>
      `,
    },
  ];

  return (
    <div className={`${colors.bg.primary} min-h-screen`}>
      <div className="max-w-5xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
        {/* Header */}
        <div className="text-center mb-16">
          <Shield className={`${colors.text.accent3} w-16 h-16 mx-auto mb-6`} />
          <h1 className={`${colors.text.accent2} text-4xl font-bold mb-6`}>
            Privacy Policy
          </h1>
          <p className={`${colors.text.base} text-lg max-w-2xl mx-auto`}>
            Last Updated: April 2, 2025
          </p>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-4 gap-10">
          {/* Table of Contents - Sidebar */}
          <div className="lg:col-span-1">
            <div
              className={`sticky top-8 ${colors.bg.secondary} p-6 rounded-xl shadow-lg ${colors.border.secondary.class}`}
            >
              <h3
                className={`${colors.text.accent2} text-xl font-semibold mb-6`}
              >
                Contents
              </h3>
              <nav>
                <ul className="space-y-4">
                  {sections.map((section) => (
                    <li key={section.id}>
                      <a
                        href={`#${section.id}`}
                        onClick={() => setActiveSection(section.id)}
                        className={`flex items-center gap-2 py-1.5 px-2 rounded-lg transition-colors duration-200 ${
                          activeSection === section.id
                            ? `${colors.text.accent3} font-medium bg-opacity-10 bg-blue-500`
                            : colors.text.base
                        } hover:${colors.text.accent3}`}
                      >
                        {React.cloneElement(section.icon, {
                          className: 'w-4 h-4',
                        })}
                        <span>{section.title}</span>
                      </a>
                    </li>
                  ))}
                </ul>
              </nav>
            </div>
          </div>

          {/* Policy Sections - Main Content */}
          <div className="lg:col-span-3 space-y-16">
            {sections.map((section) => (
              <div key={section.id} id={section.id} className="scroll-mt-8">
                <div className="flex items-center gap-3 mb-6">
                  {section.icon}
                  <h2
                    className={`${colors.text.accent2} text-2xl font-semibold`}
                  >
                    {section.title}
                  </h2>
                </div>
                <div
                  className={`${colors.bg.secondary} p-8 rounded-xl shadow-lg ${colors.border.secondary.class}`}
                >
                  <div
                    className={`${colors.text.base} text-lg leading-relaxed`}
                    dangerouslySetInnerHTML={{
                      __html: section.content,
                    }}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Footer */}
        <div className="mt-20 text-center border-t pt-8 border-gray-700">
          <p className={`${colors.text.muted} text-sm`}>
            This privacy policy is designed to help you understand how we handle
            your information.
          </p>
        </div>

        {/* Back to Top Button */}
        {showBackToTop && (
          <button
            onClick={scrollToTop}
            aria-label="Back to top"
            className={`fixed bottom-8 right-8 p-3 rounded-full shadow-lg transition-all duration-300 
                      transform hover:scale-110 focus:outline-none ${colors.bg.secondary} ${colors.border.primary.class}`}
          >
            <ArrowUp className={`${colors.text.accent3} w-5 h-5`} />
          </button>
        )}
      </div>
    </div>
  );
};

export { PrivacyPolicy };
