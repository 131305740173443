import { createSlice } from '@reduxjs/toolkit';
import { errorHandler } from '../../utils/error-handler.util';
import { Blog } from '@carousel/nexus';
import { initialAdminNewsState } from '../state/admin-news.state';
import {
  createBlog,
  deleteBlog,
  fetchBlog,
  fetchBlogs,
  updateBlog,
} from '../../services/admin/content/news/admin-news.service';

const adminNewsSlice = createSlice({
  name: 'adminNews',
  initialState: initialAdminNewsState,
  reducers: {},
  extraReducers: (builder) => {
    /* Create Blog */
    builder.addCase(createBlog.pending, (state) => {
      state.create.loading = true;
    });
    builder.addCase(createBlog.fulfilled, (state, action) => {
      state.create.loading = false;
      state.selected = action.payload as Blog;
      state.create.error = null;
    });
    builder.addCase(createBlog.rejected, (state, action) => {
      state.create.loading = false;
      state.create.error = errorHandler(action.error);
    });

    /* Fetch Blog */
    builder.addCase(fetchBlog.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchBlog.fulfilled, (state, action) => {
      state.loading = false;
      state.selected = action.payload as Blog;
      state.error = null;
    });
    builder.addCase(fetchBlog.rejected, (state, action) => {
      state.loading = false;
      state.selected = null;
      state.error = errorHandler(action.error);
    });

    /* Fetch News */
    builder.addCase(fetchBlogs.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchBlogs.fulfilled, (state, action) => {
      state.loading = false;
      state.list = action.payload as Blog[];
      state.error = null;
    });
    builder.addCase(fetchBlogs.rejected, (state, action) => {
      state.loading = false;
      state.list = [];
      state.error = errorHandler(action.error);
    });

    /* Delete Blog */
    builder.addCase(deleteBlog.pending, (state) => {
      state.delete.loading = true;
    });
    builder.addCase(deleteBlog.fulfilled, (state) => {
      state.delete.loading = false;
      state.delete.error = null;
    });
    builder.addCase(deleteBlog.rejected, (state, action) => {
      state.delete.loading = false;
      state.delete.error = errorHandler(action.error);
    });

    /* Update Blog */
    builder.addCase(updateBlog.pending, (state) => {
      state.update.loading = true;
    });
    builder.addCase(updateBlog.fulfilled, (state, action) => {
      state.update.loading = false;
      state.selected = action.payload as Blog;
      state.update.error = null;
    });
    builder.addCase(updateBlog.rejected, (state, action) => {
      state.update.loading = false;
      state.update.error = errorHandler(action.error);
    });
  },
});

const { actions, reducer } = adminNewsSlice;

const {} = actions;

export {};
export { reducer as adminNewsReducer };
