import { createSlice } from '@reduxjs/toolkit';
import { errorHandler } from '../../utils/error-handler.util';
import { SubDivision } from '@carousel/nexus';
import { initialAdminSubDivisionState } from '../state/admin-sub-divisions.state';
import {
  fetchSubDivisionsByOrganizationIdAndDivisionId,
  fetchSubDivision,
  createSubDivision,
  updateSubDivision,
} from '../../services/admin/sub-divisions/admin-sub-divisions.service';

const adminSubDivisionsSlice = createSlice({
  name: 'adminDivision',
  initialState: initialAdminSubDivisionState,
  reducers: {},
  extraReducers: (builder) => {
    /* Fetch Sub-Divisions by OrganizationId and DivisionId */
    builder.addCase(
      fetchSubDivisionsByOrganizationIdAndDivisionId.pending,
      (state) => {
        state.loading = true;
      },
    );
    builder.addCase(
      fetchSubDivisionsByOrganizationIdAndDivisionId.fulfilled,
      (state, action) => {
        state.loading = false;
        state.list = action.payload as SubDivision[];
        state.error = null;
      },
    );
    builder.addCase(
      fetchSubDivisionsByOrganizationIdAndDivisionId.rejected,
      (state, action) => {
        state.loading = false;
        state.list = [];
        state.error = errorHandler(action.error);
      },
    );

    /* Fetch Sub-Division by Id */
    builder.addCase(fetchSubDivision.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchSubDivision.fulfilled, (state, action) => {
      state.loading = false;
      state.selected = action.payload as SubDivision;
      state.error = null;
    });
    builder.addCase(fetchSubDivision.rejected, (state, action) => {
      state.loading = false;
      state.selected = null;
      state.error = errorHandler(action.error);
    });

    /* Create Sub-Division */
    builder.addCase(createSubDivision.pending, (state) => {
      state.create.loading = true;
    });
    builder.addCase(createSubDivision.fulfilled, (state, action) => {
      state.update.loading = false;
      state.selected = action.payload as SubDivision;
      state.create.error = null;
    });
    builder.addCase(createSubDivision.rejected, (state, action) => {
      state.create.loading = false;
      state.selected = null;
      state.create.error = errorHandler(action.error);
    });

    /* Update Sub-Division */
    builder.addCase(updateSubDivision.pending, (state) => {
      state.update.loading = true;
    });
    builder.addCase(updateSubDivision.fulfilled, (state, action) => {
      state.update.loading = false;
      state.selected = action.payload as SubDivision;
      state.update.error = null;
    });
    builder.addCase(updateSubDivision.rejected, (state, action) => {
      state.update.loading = false;
      state.selected = null;
      state.update.error = errorHandler(action.error);
    });
  },
});

const { actions, reducer } = adminSubDivisionsSlice;

const {} = actions;

export {};
export { reducer as adminSubDivisionsReducer };
