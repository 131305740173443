import { createSlice } from '@reduxjs/toolkit';
import { Rules } from '@carousel/nexus';

import { initialRulesState } from '../state/rules.state';
import { errorHandler } from '../../utils/error-handler.util';
import { fetchRules } from '../../services/rules/rules.service';

const rulesSlice = createSlice({
  name: 'rules',
  initialState: initialRulesState,
  reducers: {},
  extraReducers: (builder) => {
    /* Fetch Blog */
    builder.addCase(fetchRules.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchRules.fulfilled, (state, action) => {
      state.loading = false;
      state.selected = action.payload as Rules;
      state.error = null;
    });
    builder.addCase(fetchRules.rejected, (state, action) => {
      state.loading = false;
      state.selected = null;
      state.error = errorHandler(action.error);
    });
  },
});

const { actions, reducer } = rulesSlice;

const {} = actions;

export {};
export { reducer as rulesReducer };
