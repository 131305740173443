import { Organization } from '@carousel/nexus';
import { CarouselError } from '../../types/errors/carousel-error.type';

type OrganizationState = {
  loading: boolean;
  create: OrganizationCreateState;
  error: CarouselError | null;
  selected: Organization | null;
};

type OrganizationCreateState = {
  loading: boolean;
  newOrganization?: Organization | null;
  error: CarouselError | null;
};

const initialOrganizationState: OrganizationState = {
  loading: false,
  create: {
    loading: false,
    error: null,
  },
  error: null,
  selected: null,
};

export { initialOrganizationState };
export type { OrganizationState };
