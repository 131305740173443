import { SubDivision } from '@carousel/nexus';
import { CarouselError } from '../../types/errors/carousel-error.type';

type AdminSubDivisionState = {
  list: SubDivision[];
  selected: SubDivision | null;
  loading: boolean;
  update: AdminSubDivisionUpdateState;
  create: AdminSubDivisionCreateState;
  error: CarouselError | null;
};

type AdminSubDivisionCreateState = {
  loading: boolean;
  error: CarouselError | null;
};

type AdminSubDivisionUpdateState = {
  loading: boolean;
  error: CarouselError | null;
};

const initialAdminSubDivisionState: AdminSubDivisionState = {
  list: [],
  selected: null,
  loading: false,
  create: {
    loading: false,
    error: null,
  },
  update: {
    loading: false,
    error: null,
  },
  error: null,
};

export { initialAdminSubDivisionState };
export type { AdminSubDivisionState };
