import { createAsyncThunk } from '@reduxjs/toolkit';
import axios, { AxiosRequestConfig } from 'axios';

import * as changeCase from 'change-case';
import { Organization, OrganizationType } from '@carousel/nexus';
import { CreateOrgFormDataType } from '../../views/organization/create/models/create-org-form-data-type.type';
import { gameOptions } from '../../views/organization/create/data/game-options.data';

const baseUrl = import.meta.env.VITE_BFF_BASE_URL || 'https://bff.carousel.gg';

type CreateOrganizationRequest = {
  name: string;
  description?: string;
  logoUrl?: string;
  code?: string;
  divisions?: {
    name: string;
    isActive: boolean;
    sub?: { name: string }[];
  }[];
  isLeague: boolean;
  isActive: boolean;
};

const createOrganization = createAsyncThunk(
  'organization/createOrganization',
  async ({
    userId,
    organizationType,
    formData,
  }: {
    userId: string;
    organizationType: OrganizationType;
    formData: CreateOrgFormDataType;
  }): Promise<Organization> => {
    const newOrganization: CreateOrganizationRequest = {
      name: formData.name || '',
      description: formData.description,
      logoUrl: formData.logoUrl,
      code: formData.code,
      isLeague: organizationType === 'league',
      isActive: true,
    };

    // Only add divisions if there are any
    if (formData.divisions && formData.divisions.length > 0) {
      newOrganization.divisions = formData.divisions.map((division) => {
        // Create base division object
        const option = gameOptions.find((option) => option.id === division);
        if (!option) {
          throw new Error('Invalid division');
        }
        const divisionObj: {
          name: string;
          isActive: boolean;
          sub?: { name: string }[];
        } = {
          name: option.name,
          isActive: true,
        };

        // Get subdivisions for this division
        const subdivisions = formData.subdivisions[division] || [];

        // Only add the sub property if there are subdivisions
        if (subdivisions.length > 0) {
          divisionObj.sub = subdivisions.map((skillTier) => ({
            name: skillTier,
          }));
        }

        return divisionObj;
      });
    }

    const body = {
      userId,
      organization: newOrganization,
    };

    const response = await axios.post(`${baseUrl}/organization`, body);

    if (response.status !== 201) {
      throw new Error('Error creating organization');
    }

    return response.data as Organization;
  },
);

const fetchOrganizationByKebabCasedName = createAsyncThunk(
  'organization/fetchLeagueByKebabCasedName',
  async (name: string): Promise<Organization> => {
    const config: AxiosRequestConfig = {
      params: {
        name: changeCase.kebabCase(name),
        inactiveDivisions: false,
        inactiveSubDivisions: false,
      },
    };

    const response = await axios.get(
      `${baseUrl}/organization/${changeCase.kebabCase(name)}`,
      config,
    );

    if (response.status !== 200) {
      throw new Error('Error fetching organization by name');
    }
    return response.data as Organization;
  },
);

export { createOrganization, fetchOrganizationByKebabCasedName };
