import { createSlice } from '@reduxjs/toolkit';
import { initialSubDivisionsState } from '../state/sub-division.state';
import { SubDivision } from '@carousel/nexus';

const subSubDivisionSlice = createSlice({
  name: 'subDivision',
  initialState: initialSubDivisionsState,
  reducers: {
    setSubDivisionsForDivision: (state, action) => {
      state.list = action.payload as SubDivision[];
    },
    selectSubDivisionsForDivision: (state, action) => {
      state.list = action.payload.sub as SubDivision[];
    },
    setSelectedSubDivision: (state, action) => {
      state.selected = action.payload as SubDivision;
    },
  },
});

const { actions, reducer } = subSubDivisionSlice;

const {
  setSubDivisionsForDivision,
  selectSubDivisionsForDivision,
  setSelectedSubDivision,
} = actions;

export {
  setSubDivisionsForDivision,
  selectSubDivisionsForDivision,
  setSelectedSubDivision,
};
export { reducer as subDivisionReducer };
