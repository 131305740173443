import { cn, ColorScheme } from '@carousel/nexus';

const Footer = ({ colors }: { colors: ColorScheme }) => {
  return (
    <footer
      className={cn(
        'flex flex-col items-center justify-center w-full h-16 bg-gray-800 text-white',
        `${colors.bg.primary} ${colors.text.base}`,
      )}
    >
      <div className="text-sm">
        &copy; {new Date().getFullYear()} Carousel, Inc. All rights reserved.
      </div>
      <div className="text-xs mt-1">
        <a href="/privacy" className="text-gray-300 hover:text-white">
          Privacy Policy
        </a>
      </div>
    </footer>
  );
};

export { Footer };
