import { ColorScheme } from '@carousel/nexus';
import { Link, useLocation } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { useAppSelector } from '../../../store/hooks';
import { mapNavigationItemIcon } from '../../../utils/map-navigation-item-icon.util';
import { NavItem } from '../../../types/layout/nav-item.type';
import { isActive } from './utils/is-active-check.util';

type SideNavProps = {
  colors: ColorScheme;
  isOpen: boolean;
  isMobile: boolean;
  onClose: () => void;
  navContainerRef: React.RefObject<HTMLDivElement | null>;
};

const SideNav = ({
  colors,
  isOpen,
  isMobile,
  onClose,
  navContainerRef,
}: SideNavProps) => {
  const location = useLocation();
  const navRef = useRef<HTMLElement>(null);
  const [navItems, setNavItems] = useState<NavItem[]>([]);
  const stateNavItems = useAppSelector((state) => state.layout.navItems);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const isClickingNav = navRef.current?.contains(event.target as Node);
      const isClickingHeader = navContainerRef?.current?.contains(
        event.target as Node,
      );

      if (isMobile && isOpen && !isClickingNav && !isClickingHeader) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [isMobile, isOpen, onClose, navContainerRef]);

  useEffect(() => {
    // Create a properly typed copy of the nav items with the icon component
    const items = stateNavItems.map((item) => {
      // Create a new object that preserves all properties
      const newItem: NavItem = {
        ...item,
        // Add the icon component but keep the original iconName
        icon: mapNavigationItemIcon(item.iconName),
      };
      return newItem;
    });

    setNavItems(items);
  }, [stateNavItems]);

  return (
    <>
      {/* Overlay backdrop for mobile - positioned below nav */}
      {isMobile && isOpen && (
        <div
          className={`fixed inset-0 ${isMobile ? 'top-[64px]' : 'top-[104px]'} bg-black/50 z-30`}
          aria-hidden="true"
        />
      )}

      <nav
        ref={navRef}
        className={`fixed left-0 ${isMobile ? 'top-[64px]' : 'top-[104px]'} bottom-0 w-[150px] transform transition-transform duration-300 ease-in-out 
    ${isOpen ? 'translate-x-0' : '-translate-x-full'} 
    ${colors.bg.primary}
    ${isMobile ? 'z-30' : 'z-20'}
    shadow-[4px_0_8px_-4px_rgba(0,0,0,0.15)]
    flex flex-col`}
      >
        {/* Scrollable navigation items container */}
        <div className="flex-1 overflow-y-auto pb-[65px]">
          <div className="flex flex-col gap-2 p-2 w-full">
            {navItems.map((item, index) => {
              const Icon = item.icon;

              // Use custom isActiveCheck function if provided,
              // otherwise use our improved isActive function
              const isActiveCheckFn = item.isActiveCheck || isActive;
              let active = isActiveCheckFn(location.pathname, item.path);

              // Extra protection for root path - should only be active when exactly on /
              if (item.path === '/' && location.pathname !== '/') {
                active = false;
              }

              // Enforce specific rules for organization paths (both regular and manage)
              if (
                location.pathname.includes('/manage/org/') ||
                location.pathname.includes('/org/')
              ) {
                const pathSegments = location.pathname
                  .split('/')
                  .filter(Boolean);

                // Handle based on path type (manage/org or regular org)
                const isManageOrgPath =
                  pathSegments[0] === 'manage' && pathSegments[1] === 'org';
                const isRegularOrgPath = pathSegments[0] === 'org';

                // Extract the nav path segments
                const navSegments = item.path.split('/').filter(Boolean);

                if (isManageOrgPath && pathSegments.length >= 4) {
                  // For manage org paths with subsections
                  const currentSection = pathSegments[3]; // e.g., content, divisions

                  const navIsManageOrg =
                    navSegments.length >= 3 &&
                    navSegments[0] === 'manage' &&
                    navSegments[1] === 'org';

                  if (navIsManageOrg && navSegments.length >= 4) {
                    const navSection = navSegments[3];

                    // Section nav item (content, divisions, etc.)
                    if (navSection) {
                      active = navSection === currentSection;
                    } else if (navSegments.length === 3) {
                      // Organization item itself - only active on exact match or details
                      active = currentSection === 'details';
                    }
                  }
                } else if (isRegularOrgPath) {
                  // For regular org paths
                  // If we have a subsection (like news, rules)
                  const currentSection =
                    pathSegments.length >= 3 ? pathSegments[2] : null;

                  const navIsRegularOrg =
                    navSegments.length >= 2 && navSegments[0] === 'org';

                  if (navIsRegularOrg) {
                    if (navSegments.length === 2) {
                      // Base org item
                      active =
                        !currentSection ||
                        currentSection === 'info' ||
                        currentSection === '';
                    } else if (navSegments.length >= 3) {
                      // Section nav item (news, rules, etc)
                      const navSection = navSegments[2];
                      active = navSection === currentSection;
                    }
                  }
                }
              }

              return (
                <div key={item.path} className="w-full">
                  <Link
                    to={item.path}
                    onClick={() => isMobile && onClose()}
                    className={`w-full flex flex-col items-center justify-center h-[110px]
                      relative overflow-hidden group ${colors.text.base} rounded-lg
                      transform hover:scale-[1.02] active:scale-[0.98]
                      ${active ? colors.text.accent3 : ''}`}
                  >
                    <div
                      className={`absolute inset-0 bg-gradient-to-r from-violet-500/0 via-violet-500/5 to-purple-500/0 
                      transition-opacity duration-200 ${active ? 'opacity-100' : 'opacity-0 group-hover:opacity-100'}`}
                    />

                    {/* Only render the purple line when active */}
                    {active && (
                      <div className="absolute right-0 top-0 bottom-0 w-1 bg-violet-400" />
                    )}

                    <div className="relative z-10 flex flex-col items-center gap-2">
                      {Icon && (
                        <Icon
                          size={28}
                          className="transition-colors duration-200"
                        />
                      )}
                      <span className="text-base font-medium text-inherit text-center">
                        {item.label}
                      </span>
                    </div>
                  </Link>

                  {index < navItems.length - 1 && (
                    <div className="h-px bg-gray-700/30 mt-2 w-full" />
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </nav>
    </>
  );
};

export { SideNav };
export type { SideNavProps };
