import { Blog } from '@carousel/nexus';
import { CarouselError } from '../../types/errors/carousel-error.type';

type AdminNewsState = {
  list: Blog[];
  selected: Blog | null;
  loading: boolean;
  create: AdminNewsCreateState;
  delete: AdminNewsCreateState;
  update: AdminNewsUpdateState;
  error: CarouselError | null;
};

type AdminNewsCreateState = {
  loading: boolean;
  error: CarouselError | null;
};

type AdminNewsUpdateState = {
  loading: boolean;
  error: CarouselError | null;
};

const initialAdminNewsState: AdminNewsState = {
  list: [],
  selected: null,
  loading: false,
  create: {
    loading: false,
    error: null,
  },
  delete: {
    loading: false,
    error: null,
  },
  update: {
    loading: false,
    error: null,
  },
  error: null,
};

export { initialAdminNewsState };
export type { AdminNewsState };
