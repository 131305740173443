import { NavItem } from '../../types/layout/nav-item.type';
import { IconName } from '../../types/layout/icon-item.type';

const getManageOrgNavItems = (kebabCasedName: string): NavItem[] => {
  return [
    {
      label: 'Organization',
      path: `manage/org/${kebabCasedName}/`,
      iconName: IconName.BUILDING_2,
    },
    {
      label: 'Divisions',
      path: `manage/org/${kebabCasedName}/divisions`,
      iconName: IconName.SQUARE_SPLIT_HORIZONTAL,
    },
    {
      label: 'Content',
      path: `manage/org/${kebabCasedName}/content`,
      iconName: IconName.NEWSPAPER,
    },
    {
      label: 'Rules',
      path: `manage/org/${kebabCasedName}/rules`,
      iconName: IconName.GAVEL,
    },
  ];
};

export { getManageOrgNavItems };
