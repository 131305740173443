import { lazy, memo } from 'react';
import { RouteObject } from 'react-router-dom';
import { ManageOrganization } from '../views/manage-organization/manage-organization.component';
import { Navigate } from 'react-router-dom';
import { Error404 } from '../views/session/error-404.component';

const ManageOrganizationTabs = memo(
  lazy(() =>
    import(
      '../views/manage-organization/manage-organization-tabs.component'
    ).then((module) => ({
      default: module.ManageOrganizationTabs,
    })),
  ),
);

const Details = memo(
  lazy(() =>
    import('../views/manage-organization/details/details.component').then(
      (module) => ({
        default: module.Details,
      }),
    ),
  ),
);

const Divisions = memo(
  lazy(() =>
    import('../views/manage-organization/divisions/divisions.component').then(
      (module) => ({
        default: module.Divisions,
      }),
    ),
  ),
);

const DivisionList = memo(
  lazy(() =>
    import(
      '../views/manage-organization/divisions/division-list.component'
    ).then((module) => ({
      default: module.DivisionList,
    })),
  ),
);

const DivisionDetails = memo(
  lazy(() =>
    import(
      '../views/manage-organization/divisions/details/division-details.component'
    ).then((module) => ({
      default: module.DivisionDetails,
    })),
  ),
);

const SubDivisionDetails = memo(
  lazy(() =>
    import(
      '../views/manage-organization/divisions/sub-divisions/details/sub-division-details.component'
    ).then((module) => ({
      default: module.SubDivisionDetails,
    })),
  ),
);

const Content = memo(
  lazy(() =>
    import('../views/manage-organization/content/content.component').then(
      (module) => ({
        default: module.Content,
      }),
    ),
  ),
);

const ManageNewsFeed = memo(
  lazy(() =>
    import(
      '../views/manage-organization/content/news/manage-news-feed.component'
    ).then((module) => ({
      default: module.ManageNewsFeed,
    })),
  ),
);

const ManageBlog = memo(
  lazy(() =>
    import(
      '../views/manage-organization/content/news/manage-blog.component'
    ).then((module) => ({
      default: module.ManageBlog,
    })),
  ),
);

const ManageBanners = memo(
  lazy(() =>
    import(
      '../views/manage-organization/content/banners/manage-banners.component'
    ).then((module) => ({
      default: module.ManageBanners,
    })),
  ),
);

const Banner = memo(
  lazy(() =>
    import(
      '../views/manage-organization/content/banners/banner.component'
    ).then((module) => ({
      default: module.Banner,
    })),
  ),
);

const Rules = memo(
  lazy(() =>
    import('../views/manage-organization/rules/rules.component').then(
      (module) => ({
        default: module.Rules,
      }),
    ),
  ),
);

const manageOrganizationRoutes: RouteObject[] = [
  {
    path: 'manage/org/:kebabCasedOrganizationName',
    Component: ManageOrganization,
    children: [
      {
        element: <ManageOrganizationTabs />,
        children: [
          {
            path: '',
            element: <Navigate to="details" replace />,
          },
          {
            path: 'details',
            element: <Details />,
          },
          {
            path: '*',
            element: <Error404 />,
          },
        ],
      },
      {
        path: 'divisions',
        element: <Divisions />,
        children: [
          {
            path: '',
            element: <DivisionList />,
          },
          {
            path: ':divisionId',
            element: <DivisionDetails />,
          },
          {
            path: 'new',
            element: <DivisionDetails />,
          },
        ],
      },
      {
        path: 'sub-divisions/:divisionId/:subDivisionId',
        element: <SubDivisionDetails />,
      },
      {
        path: 'sub-divisions/:divisionId/new',
        element: <SubDivisionDetails />,
      },
      {
        element: <Content />,
        path: 'content',
        children: [
          {
            path: '',
            element: <Navigate to="news" replace />,
          },
          {
            path: 'news',
            element: <ManageNewsFeed />,
          },
          {
            path: 'news/blog/:blogId',
            element: <ManageBlog />,
          },
          {
            path: 'news/create',
            element: <ManageBlog />,
          },
          {
            path: 'banners',
            element: <ManageBanners />,
          },
          {
            path: 'banners/create',
            element: <Banner />,
          },
          {
            path: 'banner/:bannerId',
            element: <Banner />,
          },
          {
            path: '*',
            element: <Error404 />,
          },
        ],
      },
      {
        path: '*',
        element: <Error404 />,
      },
      {
        path: 'rules',
        element: <Rules />,
      },
    ],
  },
];

export { manageOrganizationRoutes };
