import { ColorScheme, Heading } from '@carousel/nexus';

const OrganizationTitle = ({
  name,
  colors,
  isMobile,
  className,
}: {
  name: string;
  colors: ColorScheme;
  isMobile: boolean;
  className?: string;
}) => {
  const accent2Base = colors.text.accent2.replace('text-', '');
  const accent3Base = colors.text.accent3.replace('text-', '');

  return (
    <div
      className={`relative w-full overflow-hidden pb-10 cursor-pointer ${className || ''}`}
    >
      {/* Main title container */}
      <div className="relative flex flex-col items-center">
        {/* Glowing top line */}
        <div
          className={`w-32 h-1 bg-${accent2Base} mb-4 animate-pulse shadow-lg shadow-${accent2Base}/50`}
        />

        {/* Title with stronger styling */}
        <div className="relative">
          {/* Background glow effect */}
          <div className={`absolute inset-0 bg-${accent3Base}/20 blur-xl`} />

          <Heading
            size="h1"
            className={`
              relative
              ${colors.text.accent2}
              font-extrabold
              uppercase
              tracking-widest
              text-center
              px-6
              ${isMobile ? 'text-4xl' : 'text-6xl'}
              text-shadow-lg
              transform
              hover:scale-105
              transition-transform
              duration-300
            `}
          >
            {name}
          </Heading>
        </div>

        {/* Bottom decorative elements */}
        <div className="flex items-center gap-4 mt-4">
          <div
            className={`w-16 h-1 bg-${accent3Base} shadow-lg shadow-${accent3Base}/50`}
          />
          <div
            className={`w-4 h-4 bg-${accent2Base} rotate-45 shadow-lg shadow-${accent2Base}/50`}
          />
          <div
            className={`w-16 h-1 bg-${accent3Base} shadow-lg shadow-${accent3Base}/50`}
          />
        </div>
      </div>
    </div>
  );
};

export { OrganizationTitle };
