import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import { Blog } from '@carousel/nexus';

const baseUrl = import.meta.env.VITE_BFF_BASE_URL || 'https://bff.carousel.gg';

type FetchBannersProps = {
  organizationId?: string;
  divisionId?: string;
  subDivisionId?: string;
};

const fetchBlog = createAsyncThunk(
  'news/fetchBlog',
  async ({ blogId }: { blogId?: string | null }): Promise<Blog> => {
    let url = `${baseUrl}/news/blog/${blogId}/`;

    const response = await axios.get(url);

    if (response.status !== 200) {
      throw new Error('Error fetching blog');
    }
    return response.data.blog as Blog;
  },
);

const fetchNewsFeed = createAsyncThunk(
  'news/fetchNewsFeed',
  async (props: FetchBannersProps): Promise<Blog[]> => {
    let url = `${baseUrl}/news/blogs`;
    const { organizationId, divisionId, subDivisionId } = props;
    if (organizationId) {
      url += `/${props.organizationId}`;
      if (divisionId) {
        url += `/${props.divisionId}`;
        if (subDivisionId) {
          url += `/${props.subDivisionId}`;
        }
      }
    }

    const response = await axios.get(url);

    if (response.status !== 200) {
      throw new Error('Error fetching news feed');
    }
    return response.data.blogs as Blog[];
  },
);

export { fetchBlog, fetchNewsFeed };
