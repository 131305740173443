import { Blog } from '@carousel/nexus';
import { CarouselError } from '../../types/errors/carousel-error.type';

type NewsState = {
  loading: boolean;
  selected: Blog | null;
  error: CarouselError | null;
  items: Blog[];
};

const initialNewsState: NewsState = {
  loading: false,
  selected: null,
  error: null,
  items: [],
};

export { initialNewsState };
export type { NewsState };
