import { GameOption } from '../models/game-option.type';

const gameOptions: GameOption[] = [
  { id: 'lol', name: 'League of Legends' },
  { id: 'valorant', name: 'Valorant' },
  { id: 'cs2', name: 'Counter-Strike 2' },
  { id: 'rocketLeague', name: 'Rocket League' },
  { id: 'marvelRivals', name: 'Marvel Rivals' },
];

export { gameOptions };
