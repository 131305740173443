import { BannerItem } from '@carousel/nexus';
import { CarouselError } from '../../types/errors/carousel-error.type';

type AdminBannersState = {
  list: BannerItem[];
  selected: BannerItem | null;
  create: AdminBannerCreateState;
  update: AdminBannerUpdateState;
  loading: boolean;
  error: CarouselError | null;
};

type AdminBannerCreateState = {
  loading: boolean;
  error: CarouselError | null;
};

type AdminBannerUpdateState = {
  loading: boolean;
  error: CarouselError | null;
};

const initialAdminBannersState: AdminBannersState = {
  list: [],
  selected: null,
  create: {
    loading: false,
    error: null,
  },
  update: {
    loading: false,
    error: null,
  },
  loading: false,
  error: null,
};

export { initialAdminBannersState };
export type { AdminBannersState };
