import { Division } from '@carousel/nexus';
import { CarouselError } from '../../types/errors/carousel-error.type';

type AdminDivisionState = {
  list: Division[];
  selected: Division | null;
  loading: boolean;
  update: AdminDivisionUpdateState;
  create: AdminDivisionCreateState;
  error: CarouselError | null;
};

type AdminDivisionCreateState = {
  loading: boolean;
  error: CarouselError | null;
};

type AdminDivisionUpdateState = {
  loading: boolean;
  error: CarouselError | null;
};

const initialAdminDivisionState: AdminDivisionState = {
  list: [],
  selected: null,
  loading: false,
  create: {
    loading: false,
    error: null,
  },
  update: {
    loading: false,
    error: null,
  },
  error: null,
};

export { initialAdminDivisionState };
export type { AdminDivisionState };
