import { lazy, memo } from 'react';
import { RouteObject } from 'react-router-dom';
import { PrivacyPolicy } from '../views/policies/privacy/privacy-policy.component';

const LandingPage = memo(
  lazy(() =>
    import('../views/landing-page/landing-page.component').then((module) => ({
      default: module.LandingPage,
    })),
  ),
);

const About = memo(
  lazy(() =>
    import('../views/landing-page/about/about.component').then((module) => ({
      default: module.About,
    })),
  ),
);

const Help = memo(
  lazy(() =>
    import('../views/landing-page/help/help.component').then((module) => ({
      default: module.Help,
    })),
  ),
);

const HowItWorks = memo(
  lazy(() =>
    import('../views/landing-page/how-it-works/how-it-works.component').then(
      (module) => ({
        default: module.HowItWorks,
      }),
    ),
  ),
);

const Play = memo(
  lazy(() =>
    import('../views/landing-page/play/play.component').then((module) => ({
      default: module.Play,
    })),
  ),
);

const Services = memo(
  lazy(() =>
    import('../views/landing-page/services/services.component').then(
      (module) => ({
        default: module.Services,
      }),
    ),
  ),
);

const CreateOrgniazation = memo(
  lazy(() =>
    import('../views/organization/create/create-organization.component').then(
      (module) => ({
        default: module.CreateOrganization,
      }),
    ),
  ),
);

const homeRoutes: RouteObject[] = [
  {
    path: '/',
    element: <LandingPage />,
  },
  {
    path: '/about',
    element: <About />,
  },
  {
    path: '/help',
    element: <Help />,
  },
  {
    path: '/how-it-works',
    element: <HowItWorks />,
  },
  {
    path: '/play',
    element: <Play />,
  },
  {
    path: '/play/org/create',
    element: <CreateOrgniazation />,
  },
  {
    path: '/services',
    element: <Services />,
  },

  {
    path: '/privacy',
    element: <PrivacyPolicy />,
  },
];

export { homeRoutes };
