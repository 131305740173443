import { createSlice } from '@reduxjs/toolkit';
import { errorHandler } from '../../utils/error-handler.util';
import { Division } from '@carousel/nexus';
import { initialAdminDivisionState } from '../state/admin-divisions.state';
import {
  fetchDivisionsByOrganizationId,
  fetchDivisionByOrganizationIdAndDivisionById,
  updateDivision,
  createDivision,
} from '../../services/admin/divisions/admin-divisions.service';

const adminDivisionsSlice = createSlice({
  name: 'adminDivision',
  initialState: initialAdminDivisionState,
  reducers: {},
  extraReducers: (builder) => {
    /* Fetch Divisions by OrganizationId */
    builder.addCase(fetchDivisionsByOrganizationId.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      fetchDivisionsByOrganizationId.fulfilled,
      (state, action) => {
        state.loading = false;
        state.list = action.payload as Division[];
        state.error = null;
      },
    );
    builder.addCase(
      fetchDivisionsByOrganizationId.rejected,
      (state, action) => {
        state.loading = false;
        state.list = [];
        state.error = errorHandler(action.error);
      },
    );

    /* Fetch Division by Id */
    builder.addCase(
      fetchDivisionByOrganizationIdAndDivisionById.pending,
      (state) => {
        state.loading = true;
      },
    );
    builder.addCase(
      fetchDivisionByOrganizationIdAndDivisionById.fulfilled,
      (state, action) => {
        state.loading = false;
        state.selected = action.payload as Division;
        state.error = null;
      },
    );
    builder.addCase(
      fetchDivisionByOrganizationIdAndDivisionById.rejected,
      (state, action) => {
        state.loading = false;
        state.selected = null;
        state.error = errorHandler(action.error);
      },
    );

    /* Create Division */
    builder.addCase(createDivision.pending, (state) => {
      state.create.loading = true;
    });
    builder.addCase(createDivision.fulfilled, (state, action) => {
      state.update.loading = false;
      state.selected = action.payload as Division;
      state.create.error = null;
    });
    builder.addCase(createDivision.rejected, (state, action) => {
      state.create.loading = false;
      state.selected = null;
      state.create.error = errorHandler(action.error);
    });

    /* Update Division */
    builder.addCase(updateDivision.pending, (state) => {
      state.update.loading = true;
    });
    builder.addCase(updateDivision.fulfilled, (state, action) => {
      state.update.loading = false;
      state.selected = action.payload as Division;
      state.update.error = null;
    });
    builder.addCase(updateDivision.rejected, (state, action) => {
      state.update.loading = false;
      state.selected = null;
      state.update.error = errorHandler(action.error);
    });
  },
});

const { actions, reducer } = adminDivisionsSlice;

const {} = actions;

export {};
export { reducer as adminDivisionsReducer };
