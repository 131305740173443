import { createAsyncThunk } from '@reduxjs/toolkit';
import axios, { AxiosRequestConfig } from 'axios';

import { SubDivision } from '@carousel/nexus';

const baseUrl = import.meta.env.VITE_BFF_BASE_URL || 'https://bff.carousel.gg';

const fetchSubDivisionsByOrganizationIdAndDivisionId = createAsyncThunk(
  'adminSubDivision/fetchSubDivisionsByOrganizationIdAndDivisionId',
  async ({
    organizationId,
    divisionId,
    activeOnly,
  }: {
    organizationId: string;
    divisionId: string;
    activeOnly?: boolean;
  }): Promise<SubDivision[]> => {
    const response = await axios.get(
      `${baseUrl}/sub-division/${organizationId}/${divisionId}`,
    );

    if (response.status !== 200) {
      throw new Error('Error fetching sub-divisions');
    }

    if (activeOnly) {
      return response.data.filter(
        (subDivision: SubDivision) => subDivision.isActive,
      ) as SubDivision[];
    }

    return response.data as SubDivision[];
  },
);

const fetchSubDivision = createAsyncThunk(
  'adminSubDivision/fetchSubDivision',
  async ({
    organizationId,
    divisionId,
    subDivisionId,
  }: {
    organizationId: string;
    divisionId: string;
    subDivisionId: string;
  }): Promise<SubDivision | null> => {
    if (!subDivisionId) {
      return null;
    }

    const response = await axios.get(
      `${baseUrl}/sub-division/${organizationId}/${divisionId}/${subDivisionId}`,
    );

    if (response.status !== 200) {
      throw new Error('Error fetching sub-division');
    }
    return response.data as SubDivision;
  },
);

const createSubDivision = createAsyncThunk(
  'adminSubDivision/createSubDivision',
  async ({
    organizationId,
    divisionId,
    createSubDivisionRequestBody,
  }: {
    organizationId: string;
    divisionId: string;
    createSubDivisionRequestBody: Partial<SubDivision>;
  }): Promise<SubDivision> => {
    const config: AxiosRequestConfig = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const response = await axios.post(
      `${baseUrl}/sub-division/${organizationId}/${divisionId}`,
      createSubDivisionRequestBody,
      config,
    );

    if (response.status !== 201) {
      throw new Error('Error creating sub-division');
    }
    return response.data as SubDivision;
  },
);

const disableSubDivision = createAsyncThunk(
  'adminSubDivision/disableSubDivision',
  async ({
    organizationId,
    divisionId,
    subDivisionId,
  }: {
    organizationId: string;
    divisionId: string;
    subDivisionId: string;
  }): Promise<SubDivision> => {
    const response = await axios.put(
      `${baseUrl}/sub-division/${organizationId}/${divisionId}/${subDivisionId}/disable`,
    );

    if (response.status !== 200) {
      throw new Error('Error disabling sub-division');
    }
    return response.data as SubDivision;
  },
);

const enableSubDivision = createAsyncThunk(
  'adminSubDivision/enableSubDivision',
  async ({
    organizationId,
    divisionId,
    subDivisionId,
  }: {
    organizationId: string;
    divisionId: string;
    subDivisionId: string;
  }): Promise<SubDivision> => {
    const config: AxiosRequestConfig = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const response = await axios.put(
      `${baseUrl}/sub-division/${organizationId}/${divisionId}/${subDivisionId}/enable`,
      config,
    );

    if (response.status !== 200) {
      throw new Error('Error enabling division');
    }
    return response.data as SubDivision;
  },
);

const updateSubDivision = createAsyncThunk(
  'adminSubDivision/updateSubDivision',
  async ({
    organizationId,
    divisionId,
    subDivisionId,
    updateSubDivisionRequestBody,
  }: {
    organizationId: string;
    divisionId: string;
    subDivisionId: string;
    updateSubDivisionRequestBody: Partial<SubDivision>;
  }): Promise<SubDivision | null> => {
    if (!divisionId) {
      return null;
    }

    const config: AxiosRequestConfig = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const response = await axios.put(
      `${baseUrl}/sub-division/${organizationId}/${divisionId}/${subDivisionId}`,
      updateSubDivisionRequestBody,
      config,
    );

    if (response.status !== 200) {
      throw new Error('Error updating division');
    }
    return response.data as SubDivision;
  },
);

export {
  createSubDivision,
  disableSubDivision,
  enableSubDivision,
  fetchSubDivisionsByOrganizationIdAndDivisionId,
  fetchSubDivision,
  updateSubDivision,
};
