import { createSlice } from '@reduxjs/toolkit';
import { errorHandler } from '../../utils/error-handler.util';
import { BannerItem } from '@carousel/nexus';
import { initialAdminBannersState } from '../state/admin-banners.state';
import {
  fetchImages,
  updateImage,
} from '../../services/admin/content/banners/admin-banners.service';

const adminBannersSlice = createSlice({
  name: 'adminBanners',
  initialState: initialAdminBannersState,
  reducers: {},
  extraReducers: (builder) => {
    /* Fetch Banners */
    builder.addCase(fetchImages.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchImages.fulfilled, (state, action) => {
      state.loading = false;
      state.list = action.payload as BannerItem[];
      state.error = null;
    });
    builder.addCase(fetchImages.rejected, (state, action) => {
      state.loading = false;
      state.list = [];
      state.error = errorHandler(action.error);
    });

    /* Update Banner */
    builder.addCase(updateImage.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateImage.fulfilled, (state, action) => {
      state.loading = false;
      state.selected = action.payload as BannerItem;
      state.error = null;
    });
    builder.addCase(updateImage.rejected, (state, action) => {
      state.loading = false;
      state.selected = null;
      state.error = errorHandler(action.error);
    });
  },
});

const { actions, reducer } = adminBannersSlice;

const {} = actions;

export {};
export { reducer as adminBannersReducer };
