import { NavItem } from '../../types/layout/nav-item.type';
import { IconName } from '../../types/layout/icon-item.type';

const getOrgNavItems = (kebabCasedName: string): NavItem[] => {
  return [
    {
      label: 'Dashboard',
      path: `org/${kebabCasedName}/`,
      iconName: IconName.CIRCLE_GUAGE,
    },
    {
      label: 'News',
      path: `org/${kebabCasedName}/news`,
      iconName: IconName.NEWSPAPER,
    },
    {
      label: 'Rules',
      path: `org/${kebabCasedName}/rules`,
      iconName: IconName.BOOK,
    },
  ];
};

export { getOrgNavItems };
