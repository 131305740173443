import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import { Rules } from '@carousel/nexus';

const baseUrl = import.meta.env.VITE_BFF_BASE_URL || 'https://bff.carousel.gg';

const fetchRules = createAsyncThunk(
  'rules/fetchRules',
  async ({
    organizationId,
    divisionId,
    subDivisionId,
  }: {
    organizationId?: string | null;
    divisionId?: string | null;
    subDivisionId?: string | null;
  }): Promise<Rules> => {
    let url = `${baseUrl}/rules/`;

    if (organizationId) {
      url += `${organizationId}/`;
      if (divisionId) {
        url += `${divisionId}/`;
        if (subDivisionId) {
          url += `${subDivisionId}/`;
        }
      }
    }

    const response = await axios.get(url);

    if (response.status !== 200) {
      throw new Error('Error fetching rules');
    }
    return response.data as Rules;
  },
);

export { fetchRules };
